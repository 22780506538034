<template>
  <div>
    <TopNav />
    <v-container
      v-touch="{
        right: () => toHome(),
      }"
    >
      <div class="my-9">
        <div class="d-flex align-center justify-space-between my-6 my-sm-9">
          <div class="text-h3 font-weight-black" data-aos="fade-in">
            Contact
          </div>
          <TextLogo :width="150" accent="#97D7D8" color="#21118D" />
        </div>

        <div class="text-body-1 mb-12" data-aos="fade-in">
          Please fill out all of the information and we will get back to you.
        </div>

        <v-form v-model="validForm" lazy-validation ref="enquiryForm">
          <v-text-field
            color="black"
            outlined
            label="First name"
            v-model="firstName"
            type="text"
            ref="firstName"
            :rules="[(v) => !!v || 'Required']"
            required
          ></v-text-field>

          <v-text-field
            color="black"
            outlined
            label="Last name"
            v-model="lastName"
            type="text"
            :rules="[(v) => !!v || 'Required']"
            required
          ></v-text-field>

          <v-text-field
            color="black"
            outlined
            label="Email"
            v-model="email"
            type="email"
            :rules="[
              (v) => !!v || 'Required',
              (v) => /.+@.+/.test(v) || 'Must be valid',
            ]"
            required
          ></v-text-field>

          <v-textarea
            color="black"
            outlined
            name="message"
            label="Message"
            v-model="message"
            :rules="[(v) => !!v || 'Required']"
          ></v-textarea>

          <v-checkbox
            class="my-0 py-2"
            color="black"
            v-model="agreement"
            required
            :rules="[(v) => !!v || 'You must agree to continue!']"
          >
            <template v-slot:label>
              <div>
                I agree to the
                <a
                  class="text-decoration-none"
                  @click.stop
                  href="/privacy-policy"
                  target="_blank"
                  >privacy policy</a
                >
                and
                <a
                  class="text-decoration-none"
                  @click.stop
                  href="/terms-of-use"
                  target="_blank"
                  >terms of use</a
                >.
              </div>
            </template>
          </v-checkbox>

          <div class="mt-3">
            <v-btn
              dark
              rounded
              elevation="0"
              large
              :loading="isSending"
              @click="validate"
              class="primary"
              ><v-icon class="mr-1">mdi-send</v-icon> Send</v-btn
            >
          </div>
        </v-form>
      </div>

      <v-snackbar v-model="snack.show" color="black">
        {{ snack.text }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snack.show = false">
            Dismiss
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import MessageService from "@/services/message";

import TextLogo from "@/components/shared/TextLogo";
import TopNav from "@/components/shared/TopNav";

export default {
  metaInfo: () => ({
    title: "Contact - PatRec - Connecting Healthcare - Holden Knight Group",
  }),
  components: { TextLogo, TopNav },
  data() {
    return {
      isSending: false,
      validForm: false,
      firstName: null,
      lastName: null,
      email: null,
      message: null,
      agreement: false,
      snack: {
        show: false,
        text: null,
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.$refs.firstName.focus();
    }, 500);
  },
  methods: {
    async send() {
      if (this.isSending) return;
      this.isSending = true;

      try {
        let messageService = new MessageService();
        await messageService.contactUs({
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          message: this.message,
        });
        this.snack = {
          show: true,
          text: "Thanks! We will get back to you as soon as possible.",
        };
        this.reset();
      } catch (error) {
        this.snack = {
          show: true,
          text: "Something went wrong, please try again later.",
        };
      }
      this.isSending = false;
    },
    validate() {
      this.validForm = this.$refs.enquiryForm.validate();
      if (this.validForm) this.send();
    },
    reset() {
      this.$refs.enquiryForm.reset();
    },
    toHome() {
      this.$router.push({
        name: "Home",
      });
    },
  },
};
</script>

<style scoped></style>
