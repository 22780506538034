import axios from "axios";

export default class Contact {
    contactUs(data) {
        let promise = axios({
                method: "post",
                url: process.env.VUE_APP_API + "/contact-us",
                headers: {
                    "X-Api-Key": process.env.VUE_APP_API_KEY,
                },
                data: data
            })
            .then((res) => res.data)
            .then((data) => {
                return data;
            });

        return promise;
    }

}